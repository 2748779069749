import request from '@/utils/scm-request'
/**
 * @description 鞋标文件分页查询
 * @param {Object} params
 * @param {string} params.purchaseOrderCode
 * @param {string} params.style
 * @param {string} params.shoeState
 * @param {string} params.vendorId
 * @param {string} params.fileType
 */
export function shoeLogoPage(params) {
  return request({
    url: 'scm-purchase/shoeLogo/qryPage',
    method: 'get',
    params
  })
}
/**
 * @description 鞋标文件导出
 * @param {Object} params
 * @param {string} params.ids
 **/
export function exportLogo(params) {
  return request({
    url: 'scm-purchase/shoeLogo/downFile',
    method: 'get',
    params
  })
}
/**
 * @description 鞋标作废
 * @param {Object} params
 * @param {string} params.ids
 **/
export function cancelLogo(params) {
  return request({
    url: 'scm-purchase/shoeLogo/cancelLogo',
    method: 'get',
    params
  })
}
