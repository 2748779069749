<template>
  <div class="shoe-label">
    <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="采购单号">
        <el-input
          v-model="queryForm.purchaseOrderCode"
          clearable
          placeholder="多个以换行符分隔"
          type="textarea"
          :rows="2"
        />
      </el-form-item>
      <el-form-item label="style">
        <el-input v-model="queryForm.style" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="状态">
        <Selection
          v-model="queryForm.shoeState"
          :select-options=" _getAllCommodityDict('PURCHASE_ORDER_CONTRACT_STATUS')"
          :configuration="optionsConfig"
        />
      </el-form-item>
      <el-form-item label="供应商">
        <Selection
          v-model="queryForm.vendorId"
          api-key="vendorList"
        />
      </el-form-item>
      <el-form-item label="文件类型">
        <Selection
          v-model="queryForm.fileType"
          :select-options="_getAllCommodityDict('SHOE_FILE_TYPE')"
          :configuration="optionsConfig"
          multiple
        />
      </el-form-item>
    </QueryForm>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-button type="primary" @click="handleExport">导出</el-button>
        <el-button type="primary" :loading="cancelLoading" @click="handleCancel">作废</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      v-loading="tableLoading"
      max-height="800px"
      :data="tableData"
      align="center"
      :checkbox-config="{labelField: 'name', checkMethod: checkMethod}"
      @checkbox-all="handleTableCheckbox"
      @checkbox-change="handleTableCheckbox"
    >
      <vxe-table-column type="checkbox" width="60" />
      <vxe-table-column type="seq" width="60" title="序号" />
      <vxe-table-column field="shoeCode" title="鞋标文件编号" min-width="180" />
      <vxe-table-column field="purchaseOrderCode" title="采购订单号" min-width="200">
        <template #default="{row}">
          <el-button type="text">{{ row.purchaseOrderCode }}</el-button>
        </template>
      </vxe-table-column>
      <vxe-table-column field="vendorName" title="供应商" min-width="120" />
      <vxe-table-column field="style" title="style" min-width="120" />
      <vxe-table-column field="fileType" title="文件类型" min-width="120">
        <template #default="{ row }">
          {{ getAuditTypeLabel('SHOE_FILE_TYPE',row.fileType) }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="shoeState" title="状态" min-width="120">
        <template #default="{ row }">
          {{ getAuditTypeLabel('PURCHASE_ORDER_CONTRACT_STATUS',row.shoeState) }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="downloadsAmount" title="下载次数" min-width="120" />
      <vxe-table-column field="modifyByName" title="操作人" min-width="120" />
      <vxe-table-column field="modifyTime" title="操作时间" min-width="180" />
    </vxe-table>
    <Paging :pager="pager" end @pagination="pagerUpdate" />
  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import Selection from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import { shoeLogoPage, exportLogo, cancelLogo } from '@/api/purchaseBusiness'
import { join } from 'lodash'
export default {
  components: {
    QueryForm,
    Selection,
    Paging
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      tableLoading: false,
      tableData: [],
      queryForm: {
        purchaseOrderCode: '',
        style: '',
        shoeState: '',
        vendorId: '',
        fileType: []
      },
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      tableCheckList: [],
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      cancelLoading: false
    }
  },
  computed: {
    shoeLogoPageParams() {
      const { purchaseOrderCode, fileType } = this.queryForm
      return Object.assign({}, this.queryForm, this.pager, {
        purchaseOrderCode: purchaseOrderCode.replaceAll('\n', ','),
        fileType: join(fileType, ',')
      })
    },
    shoeIds() {
      return this.tableCheckList.map(item => item.id)?.join(',') || ''
    }
  },
  mounted() {
    this._shoeLogoPage()
  },
  methods: {
    handleTableCheckbox({ checked, records }) {
      this.tableCheckList = records
      if (checked) {
        records.forEach(item => {
          if (this.tableCheckList.every(e => item.id !== e.id)) {
            this.tableCheckList.push(item)
          }
        })
      } else {
        this.tableCheckList = this.tableCheckList.filter((item, index) => this.tableData.every(f => f.id !== item.id))
      }
    },
    async handleExport() {
      if (this.shoeIds.length === 0) {
        this.$message.warning('请勾选最少一条数据！')
        return
      }
      try {
        const { datas } = await exportLogo({ ids: this.shoeIds })
        datas?.forEach(item => {
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          iframe.style.height = 0
          iframe.src = item
          // iframe需要挂载到页面上才能触发请求
          document.body.appendChild(iframe)
          setTimeout(() => {
            iframe.remove()
          }, 1000)
        })
      } finally {
        this.exportLoading = false
      }
    },
    async handleCancel() {
      try {
        if (this.shoeIds.length === 0) {
          this.$message.warning('请勾选最少一条数据！')
          return
        }
        await this.$confirm('是否作废', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.cancelLoading = true
        const { msg } = await cancelLogo({ ids: this.shoeIds })
        this.$message.success(msg)
        this._shoeLogoPage()
      } finally {
        this.cancelLoading = false
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this._shoeLogoPage()
    },
    handleQuery() {
      this.pager.current = 1
      this.tableCheckList = []
      this._shoeLogoPage()
    },
    async _shoeLogoPage() {
      try {
        this.tableLoading = true
        const { datas: { records, pager }} = await shoeLogoPage(this.shoeLogoPageParams)
        const useSelector = records.filter(item => this.tableCheckList.some(f => f.id === item.id))
        this.tableData = records
        this.pager = pager
        if (useSelector.length !== 0) this.$refs.xTable.setCheckboxRow(useSelector, true)
      } finally {
        this.tableLoading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this._shoeLogoPage()
    },
    checkMethod({ row }) {
      return row.shoeState !== 2
    }
  }
}
</script>

<style>

</style>
